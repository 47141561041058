/**
* myTempton Module
* @version 2.9.2
* @copyright PlanetCreativ
 */


require('angular-touch')
require('angular-animate')
require('angular-resource')
require('angular-cookies')
require('angular-route')
require('angular-translate')
require('angular-angles')
require('hammerjs')
require('./vendor/angular.hammer.min')
require('angular-elastic')
require('angular-vs-repeat')
require('angular-modal-service')
require('ng-sortable')
require('./vendor/rzslider')
require('angular-country-picker')

var app = angular.module('myTempton', [
  'ngTouch',
  'ngAnimate',
  'ngResource',
  'ngCookies',
  'ngRoute',
  'pascalprecht.translate',
  'angles',
  'hmTouchEvents',
  'monospaced.elastic',
  'vs-repeat',
  'angularModalService',
  'as.sortable',
  'rzModule',
  'puigcerber.countryPicker'
]);

app.AM = app.AM || {};

app.AM.runGenerator = function (gen) {
  var args = [].slice.call( arguments, 1 ), it;
  it = gen.apply(this,args);

  return Promise.resolve()
  .then(function hendleNext (value) {
    var next = it.next(value);

    return (function hendleResult (next) {
      if (next.done) return next.value;
      else {
        return Promise.resolve(next.value)
        .then(
          hendleNext,
          function hendleError (err) {
            return Promise.resolve( it.throw(err) )
            .then( hendleResult )
          }
        )
      }
    })(next)
  });
};

(function () {
  let DOMContentLoaded = () => {
    return new Promise((res) => {
      document.addEventListener("DOMContentLoaded", function ready() {
        document.removeEventListener('DOMContentLoaded', ready);
        res();
      });
    });
  };

  let deviceready = () => {
    return new Promise((res) => {
      document.addEventListener('deviceready', function ready() {
        document.removeEventListener('deviceready', ready);
        res();
      });
    })
    .then(() => {
      FirebasePlugin.setCrashlyticsCollectionEnabled();
      console.warn('Enabled CrashlyticsCollection')
    });
  };

  let detectInsets = () => {
    if (window.AndroidNotch) {
      const style = document.documentElement.style;
      window.AndroidNotch.getInsetTop(px => {
        if (px) style.setProperty("--notch-inset-top", px + "px"); 
      }, (err) => console.error("Failed to get insets top:", err));

      window.AndroidNotch.getInsetRight(px => {
        if (px) style.setProperty("--notch-inset-right", px + "px");
      }, (err) => console.error("Failed to get insets right:", err));

      window.AndroidNotch.getInsetBottom(px => {
        if (px) style.setProperty("--notch-inset-bottom", px + "px");
      }, (err) => console.error("Failed to get insets bottom:", err));

      window.AndroidNotch.getInsetLeft(px => {
        if (px) style.setProperty("--notch-inset-left", px + "px");
      }, (err) => console.error("Failed to get insets left:", err));
    }
}

  app.AM.sleep = function (ms = 100) {
    return new Promise((res) => {
      return setTimeout(res,ms);
    })
  }

  app.AM.deviceready = Promise.resolve()
  .then(() => DOMContentLoaded())
  .then(() => {
    if (window.cordova) return deviceready();
  })
  // .then(() => {
  //   if (window.cordova && deviceIsAndroid) window.AndroidNotch.hasCutout(has => {
  //     const style = document.documentElement.style;
  //     style.setProperty("--notch-inset-top",    25 + "px");
  //     if (device && device.platform === 'Android' && +device.version === 13) style.setProperty("--notch-inset-bottom", 25 + "px");
  //     if (has) detectInsets();
  //   });
  // });

  app.AM.handleOpenURL = Promise.race([
    app.AM.sleep(2*1000).then(() => Promise.reject()),
    new Promise((res) => {
      window.handleOpenURL = (url) => res(url)
    })
  ]);

  Object.defineProperty(app, 'screen_type', {
    get () {
      if (device && device.model){
        if ((/ipad/i).test(device.model)) return 'tablet';
        else if ((/iPhone/).test(device.model)) return 'phone';
        else return null;
      } else return null;
    }
  })
}) ();

var timeout;
app.AM.fixScrollOnIOS = (function () {
  let timeout;
  return function () {
    if (!deviceIsIOS) return;
    var scrollingElements = document.querySelectorAll('.scrollable-content'),
        i = 0;

    clearTimeout(timeout);

    for( ; i < scrollingElements.length; i++) {
      scrollingElements[i].setAttribute('style',
          '-webkit-overflow-scrolling:auto;overflow:hidden;');
    }

    timeout = setTimeout(() => {
      for( i = 0; i < scrollingElements.length; i++) {
        scrollingElements[i].setAttribute('style',
            '-webkit-overflow-scrolling:touch;overflow:auto;');
      }
    }, 10);
  }
})();

app.AM.hosts = {};
Object.defineProperties(app.AM.hosts, {
  staging: {
    value: "https://app-staging-api.tempton.de",
    enumerable: true,
    configurable: true
  },

  preprod: {
    value: "https://mt-api-preprod.tc.g8s.dev",
    enumerable: true,
    configurable: true
  },

  live: {
    value: "https://app-api.tempton.de",
    enumerable: true,
    configurable: true
  },

  local: {
    value: "http://am-server.local",
    enumerable: false,
    configurable: true
  }
});

Symbol.for('instance');

class Weeks {
  constructor (startDate, endDate) {
    Object.defineProperty(this, 'startOfCurrentWeek', { value: Weeks.getStartOfWeek() });
    Object.defineProperty(this, 'start', { value: Weeks.getStartOfWeek(startDate) });
    Object.defineProperty(this, 'end', { value: Weeks.getEndOfWeek(endDate) });
  }

  get [Symbol.iterator] () {
    return function * () {
      let current = +this.start;
      let end = +this.end;

      while (current <= end) {
        yield new Date(current);
        current += Weeks.getWeeksSize(current);
      }
    }
  }

  getStartWeekByDate (date) {
    for (let sWeek of this) {
      if (date >= sWeek && date <= +sWeek+Weeks.getWeeksSize(sWeek)) {
        return sWeek;
      }
    }

    return null;
  }

  static initByRelativeRange (start, end) {
    let currenr = this.getStartOfWeek();
    return new this(
      new Date(+currenr + this.getWeeksSize(currenr,start)),
      new Date(+currenr + this.getWeeksSize(currenr,end))
    )
  }

  static getStartOfWeek (date: any = new Date) {
    date = new Date(date);
    date.setHours(0,0,0,0);
    let day = (date.getDay() + 6)%7;
    return new Date(date - day * 24*60*60*1000);
  }

  static getEndOfWeek (date = new Date) {
    let start = this.getStartOfWeek(date);
    return new Date(+start + this.getWeeksSize(start) - 1);
  }

  static getWeeksSize(date, nWeeks=1) {
    let d: any = new Date(date);
    d.setDate(d.getDate() + nWeeks * 7);
    return d - date;
  }
}
window.app = app;

Object.defineProperty(Weeks,'weekSize', { value: 7*24*60*60*1000 });
window.Weeks = Weeks;

CryptoJS.kdf["PBKDF2"] = {
  execute: function (password, keySize, ivSize, salt) {
    // Generate random salt
    if (! salt) {
      salt = CryptoJS.lib.WordArray.random(128/8);
    }
    // Derive key and IV
    var key = CryptoJS.algo.PBKDF2.create({ keySize: keySize + ivSize, iterations: 10000, hasher: CryptoJS.algo.SHA256 }).compute(password, salt);

    // Separate key and IV
    var iv = CryptoJS.lib.WordArray.create(key.words.slice(keySize), ivSize * 4);
    key.sigBytes = keySize * 4;

    // Return params
    return CryptoJS.lib.CipherParams.create({ key: key, iv: iv, salt: salt });
  }
};
require('/scripts/config.js').configureApp(app)
