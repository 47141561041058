class AAEnterPassword {
  constructor($routeParams, $translate, $location, $scope, LoaderFactory, API, ErrFactory, Notification) {
    Object.defineProperties(this, {
      LoaderFactory: { value: LoaderFactory },
      API: { value: API },
      ErrFactory: { value: ErrFactory },
      notification: { value: Notification },
      $translate: { value: $translate },
      $location: { value: $location },
      $scope: { value: $scope }
    });
    this.code = $routeParams.code;
    this.email = $routeParams.email;
    this.password = null;
    this.password_confirmation = null;
    this.userForm = null;
    this.loading = false;
  }

  submit () {
    if (this.password !== this.password_confirmation) return this.$translate(['error', 'errors.samePasswords'])
    .then((result) => this.notification.alert({
      title: 'error',
      desc: 'errors.samePasswords'
    }));
    else {
      this.loading = true;
      this.LoaderFactory.show();
      return Promise.resolve(this.API.setPasswordV3({user: {
        activation_code: this.code,
        email: this.email,
        password: this.password, 
        password_confirmation: this.password_confirmation
      }}))
      .then((data) => {
        this.LoaderFactory.hide();
        let creds = {
          email: this.email,
          pass: this.password
        };
        localStorage.setItem('activation_creds', JSON.stringify(creds));
        this.$location.path(`/aa-successfully`);
        this.$scope.$apply();
      })
      .catch((err) => {
        if (err instanceof this.ErrFactory) return err.notify(() => this.loading = false);
        else {
          console.error(err);
          this.loading = false;
        }
      })
      .then(() => this.LoaderFactory.hide());
    }
  }
}

app.component('activationAccountEnterPassword', {
  template: require('scripts/components/activation-account/aa-enter-password.html'),
  controller: AAEnterPassword
})
