class AASuccessfully {
  constructor($location,$scope) {
    this.uid = setTimeout(() => {
      $location.path('/sign-in');
      $scope.$apply();
    }, 2000);

    $scope.$on('$destroy', () => clearTimeout(this.uid));
  }
}

app.component('activationAccountSuccessfully', {
  template: require('scripts/components/activation-account/aa-successfully.html'),
  controller: AASuccessfully
})
