class ActivateAccountReminder {
	constructor($element) {
		this.$element = $element;
		localStorage.setItem('firstLoginActivatePopup', 1);
	}

	close() {
		this.$element.remove();
	}
}

app.component('activateAccountReminder', {
	template: require('scripts/components/activation-reminder-popup/activate-account-reminder.html'),
	controller: ActivateAccountReminder
});
  