class AASendEmail {
  constructor(API,ValidateService,ErrFactory,$location,$scope,LoaderFactory) {
    Object.defineProperties(this,{
      API: { value: API },
      ValidateService: { value: ValidateService },
      ErrFactory: { value: ErrFactory },
      $location: { value: $location },
      $scope: { value: $scope },
      LoaderFactory: { value: LoaderFactory }
    });

    this.userForm = null;
    this.email = null;
    this.loading = false;
  }

  submit () {
    this.loading = true;
    this.LoaderFactory.show();
    return Promise.resolve(this.API.getActivationCodeV3({user: {email: this.email.trim()}}))
    .then((data) => {
      this.LoaderFactory.hide();
      this.$location.path(`/enter-code/${this.email.trim()}`);
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.ErrFactory) return err.notify(() => this.loading = false);
      else {
        console.error(err);
        this.loading = false;
      }
    })
    .then(() => this.LoaderFactory.hide());
  }
}

app.component('activationAccountSendEmail', {
  template: require('scripts/components/activation-account/aa-send-email.html'),
  controller: AASendEmail
})
