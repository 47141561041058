class AALink {
  constructor ($location, ReducedService) {
    this.isHide = ReducedService.activation_existing_account;
  }
};

app.component('activationAccountLink', {
  template: require('scripts/components/activation-account/aa-link.html'),
  controller: AALink
});
