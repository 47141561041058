class BannerComponent {
  $scope:        any;
  $rootScope:    any;
  api:           any;
  appFactory:    any;
  dbFactory:     any;
  loaderFactory: any;
  errFactory:    any;

  banner:        any;
  bannerName:    string;
  imageLoaded:   boolean;
  callback:      any;

  reloadWatcher: any;
  constructor ($scope, $rootScope, $timeout, API, AppFactory, DBFactory, LoaderFactory, ErrFactory) {
    Object.defineProperties(this, {
      $scope:        { value: $scope        },
      $rootScope:    { value: $rootScope    },
      api:           { value: API           },
      appFactory:    { value: AppFactory    },
      dbFactory:     { value: DBFactory     },
      loaderFactory: { value: LoaderFactory },
      errFactory:    { value: ErrFactory    },
    });

    $timeout(() => {
      this.loadBanner();
      this.reloadWatcher = !this.bannerName && this.addWatcher();
    });
  }

  private async loadBanner() {
    this.loaderFactory.show();

    if (!this.appFactory.isNetwork()) this.banner = await this.loadLatestBanner();
    else {
      let call, banner, remoteBanner;
      if (this.bannerName) call = this.api.getCompanyBanners();
      else                 call = this.api.getCampaignBanner();

      try { banner = await Promise.resolve(call).then((bannersList: any) => {
        let banner;
        if (this.bannerName) banner = bannersList.length && bannersList.find(banner => banner.tag === this.bannerName);
        else banner = bannersList.length && bannersList[0];

        if (banner) return banner;
        else return Promise.reject();
      }); }
      catch(err) { return this.handleBannerError(); }

      banner.id = this.bannerName || 'campaignBanner';

      let localBanner    = await this.getOwn(banner.id);
      try { remoteBanner = await this.api.getCompanyBanner(banner.image_path, localBanner && localBanner.etag); }
      catch(err) { console.error(err); }
      
      this.banner = {...localBanner, ...banner};
      if (remoteBanner) this.updateBanner(remoteBanner);
      await this.saveBanner(this.banner);
    }

    if (!this.banner || !this.banner.base64 || !this.banner.image) this.handleBannerError();
    this.$scope.$apply();
  }

  addWatcher() {
    return this.$rootScope.$on('project.sync', () => { if (this.appFactory.isNetwork()) this.loadBanner(); });
  }

  private getOwn(id) {
    return this.dbFactory.then((ds) => ds.db)
    .then((db) => db.get('lead_assets', id))
    .catch(e => { e instanceof this.errFactory ? e.notify() : console.error(e) });
  }

  private loadLatestBanner() {
    return this.dbFactory.then((ds) => ds.db)
    .then((db) => db.values('lead_assets'))
    .then((list) => {
      let banner = list.filter(b => (this.bannerName || 'campaignBanner') === b.id);;
      return banner.length ? banner[0] : null;
    })
    .catch(e => {
      if (e instanceof this.errFactory) e.notify();
      else console.error(e);
    });
  }

  private saveBanner(banner) {
    return this.dbFactory.then((ds) => ds.db)
    .then((db) => db.put('lead_assets', banner))
    .catch(e => { e instanceof this.errFactory ? e.notify() : console.error(e) });
  }

  private updateBanner(res) {
    this.banner.base64   = this.arrayBufferToBase64(res.banner);
    this.banner.image    = `data:image/jpeg;base64,${this.banner.base64}`;
    this.banner.etag     = res.etag;
    this.banner.name     = this.bannerName;
    this.banner.saved_at = new Date();
  }

  private remove(id) {
    return this.dbFactory.then((ds) => ds.db)
    .then((db) => db.remove('lead_assets', id))
    .catch((err) => {
      console.error(err);
      throw new this.errFactory.ErrLocalDB;
    });
  }

  openBannerURLHandler() {
    if (typeof this.callback !== 'undefined') this.callback();
    else this.openBannerURL();
  }

  private openBannerURL() {
    let link = this.banner.url || this.banner.deep_link || this.banner.link;
    if (!link) return;
    else if (link.startsWith("#")) window.location.href = link;
    else window.open(link, '_system');
  }

  private handleBannerError() {
    console.error('Could not load banner');
    this.imageLoadedHandler();
    this.$scope.$apply();
  }

  imageLoadedHandler() {
    this.imageLoaded = true;
    if (this.bannerName) this.loaderFactory.hide();
  }

  errorLoading() {
    console.error('Banner image corrupted.');
    this.banner.image = null;
    this.imageLoadedHandler();
  }

  private arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  $onDestroy () {
    this.reloadWatcher && this.reloadWatcher();
  }
}

app.component('banner', {
  template: require('scripts/components/banner/banner.component.html'),
  controller: ['$scope', '$rootScope', '$timeout', 'API', 'AppFactory', 'DBFactory', 'LoaderFactory', 'ErrFactory', BannerComponent],
  bindings: {
    banner:      '=',
    bannerName:  '<',
    imageLoaded: '=',
    callback:    '&?',
    classList:   '<'
  }
});
