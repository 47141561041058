class AAEnterCode {
  constructor($routeParams,$location,$scope,LoaderFactory,API,ErrFactory, Notification) {
    Object.defineProperties(this, {
      LoaderFactory: { value: LoaderFactory },
      API: { value: API },
      ErrFactory: { value: ErrFactory },
      $location: { value: $location },
      $scope: { value: $scope },
      notification: { value: Notification }
    })

    this.code = null;
    this.userForm = null;
    this.email = $routeParams.email;
    this.timer = 59;
    setInterval(() => {
      if (this.timer) {
        --this.timer;
        this.$scope.$apply();
      }
    }, 1000);
  }

  submit () {
    this.LoaderFactory.show();
    return Promise.resolve(this.API.activateAccountV3({user: { email: this.email, activation_code: `${this.code.trim()}` }}))
    .then((data) => {
      this.LoaderFactory.hide();
      this.$location.path(`/enter-password/${this.email}/${this.code.trim()}`);
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.ErrFactory) return err.notify();
      else console.error(err);
    })
    .then(() => this.LoaderFactory.hide());
  }

  resendCode () {
    this.LoaderFactory.show();
    return Promise.resolve(this.API.getActivationCodeV3({user: {email: this.email}}))
    .then(() => this.timer = 59)
    .catch((err) => {
      if (err instanceof this.ErrFactory) return err.notify();
      else console.error(err);
    })
    .then(() => {
      this.LoaderFactory.hide();
      this.notification.alert_p({title: 'activationCodeResend', desc: 'activationCodeResendMessage', button: 'ok'}, {});
    }
  );
  }
}

app.component('activationAccountEnterCode', {
  template: require('scripts/components/activation-account/aa-enter-code.html'),
  controller: AAEnterCode
})
