class BlockerComponent {
  $rootScope:          any;
  $window:             any;
  api:                 any;
  localizationService: any;

  storeUrl: string;
  langList: string[];
  constructor ($rootScope, $window, API, LocalizationService) {
    Object.defineProperties(this, {
      $rootScope:          { value: $rootScope          },
      $window:             { value: $window             },
      api:                 { value: API                 },
      localizationService: { value: LocalizationService }
    });

    $rootScope.currentLang = localStorage.getItem('lang');
    this.storeUrl = localStorage.getItem('storeUrl');
    this.langList = LocalizationService.supportLangs;
  }

  getFlag(code: string): string {
    switch(code) {
      case 'de':
        return 'images/flags/germany.svg';
      case 'en':
        return 'images/flags/united-kingdom.svg';
      case 'ro':
        return 'images/flags/romania.svg';
      case 'po':
        return 'images/flags/poland.svg';
      case 'tr':
        return 'images/flags/turkey.svg';
      case 'hu':
        return 'images/flags/hungary.svg';
      case 'ru':
        return 'images/flags/russia.svg';
      case 'cz':
        return 'images/flags/czech-republic.svg';
      case 'hr':
        return 'images/flags/croatia.svg';
      case 'ua':
        return 'images/flags/ukraine.svg';
      case 'es':
        return 'images/flags/spain.svg';
      case 'it':
        return 'images/flags/italy.svg';
      case 'fr':
        return 'images/flags/france.svg';
    };
  }

  getLangName(code: string): string {
    switch(code) {
      case 'de':
        return 'Deutsch';
      case 'en':
        return 'English';
      case 'ro':
        return 'Română';
      case 'po':
        return 'Polski';
      case 'tr':
        return 'Türk';
      case 'hu':
        return 'Magyar';
      case 'ru':
        return 'Русский';
      case 'cz':
        return 'Čeština';
      case 'hr':
        return 'Hrvatski';
      case 'ua':
        return 'Українська';
      case 'es':
        return 'Español';
      case 'it':
        return 'Italiano';
      case 'fr':
        return 'Français';
    };
  }

  toggleLangPicker(): void {
    let langList = document.getElementsByClassName('lang-list')[0];
    if (langList.classList.contains('active')) langList.style.height = 0;
    else langList.style.height = 40 * this.langList.length - 19 + 'px';
    langList.classList.toggle('active');
  }

  hideLangPicker(): void {
    if (window.cordova) document.getElementById('blocker-lang-picker').classList.add('hidden');
  }

  showLangPicker(): void {
    if (window.cordova) document.getElementById('blocker-lang-picker').classList.remove('hidden');
  }

  onSwitchLang(langCode: string): void {
    this.$rootScope.currentLang = langCode;
    localStorage.setItem('selectedLang', langCode);
    this.localizationService.localization(langCode);
    this.toggleLangPicker();
  }

  openStore(): void {
    this.$window.open(this.storeUrl, '_system');
  }

}

window.app.component('blocker', {
  template: require('scripts/components/blocker/blocker.html'),
  controller: ['$rootScope', '$window', 'API', 'LocalizationService', BlockerComponent]
});
